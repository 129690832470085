<template>
	<div class="f1 apps-page w-full dF fC f1 px-4">
		<div class="dF aC">
			<div class="dF aC f1">
				<h3>Archived Projects</h3>
			</div>
		</div>
		<a-card :bodyStyle="{ padding: 0 }" class="my-3">
			<div>
				<a-table :rowKey="(e) => e.key" :columns="columns" :dataSource="projects" :loading="loading" :pagination="{
						current: currentPage,
						total: totalInstances,
						pageSize: pageSize,
					}" @change="handleChange">
					<div slot="readableId" slot-scope="instance">
						{{ instance.readableId }}
					</div>
					<div slot="name" slot-scope="instance">
						{{ instance.name }}
					</div>
					<div slot="projectType" slot-scope="instance">
						{{ instance.projectType }}
					</div>

					<div slot="productType" slot-scope="instance">
						{{
							instance.productType === "lowrise"
							? "Low Rise"
							: "High Rise"
						}}
					</div>
					<div slot="owner" slot-scope="instance">
						<span v-if="instance.owner">{{
							instance.owner.email
						}}</span>
					</div>
					<div slot="planType" slot-scope="instance">
						{{ instance.package && instance.package.name }}
					</div>
					<div slot="members" slot-scope="instance" class="dF aC" style="justify-content: center">
						<a-avatar style="margin-left: -10px" shape="circle" size="default" :class="$style.avatar"
							v-for="user in first3Users(instance)" :key="user.id" :style="{
									'background-color':
										user.id === 'id'
											? '#9693e7'
											: 'var(--primary)',
								}">{{
		user.firstName
		? user.id !== "id"
			? user.firstName[0].toUpperCase()
			: user.firstName
		: "--"
	}}</a-avatar>
					</div>
					<div slot="createdAt" slot-scope="instance" style="color: var(--med-gray)">
						{{ getDate(instance.createdAt) }}
					</div>
					<div slot="actions" slot-scope="instance" class="dF" style="justify-content: flex-end">
						<a-button type="primary" @click="restoreProject(instance)">Restore</a-button>
					</div>
				</a-table>
			</div>
		</a-card>
	</div>
</template>

<script>
import moment from "moment";
import { mapMutations } from "vuex";
import _ from "lodash";

export default {
	data() {
		return {
			sortedInfo: {
				key: "createdAt",
				order: "DESC",
			},
			instances: [],
			loading: false,
			currentPage: 1,
			totalInstances: 0,
			pageSize: 10,
		};
	},
	computed: {
		search() {
			return this.$store.state.searchFilter.toLowerCase();
		},
		columns() {
			return [
				{
					title: "Project ID",
					key: "readableId",
					scopedSlots: { customRender: "readableId" },
					sorter: true,
				},
				{
					title: "Project Name",
					key: "name",
					scopedSlots: { customRender: "name" },
					sorter: true,
				},
				{
					title: "Project Type",
					key: "projectType",
					scopedSlots: { customRender: "projectType" },
					sorter: true,
				},
				{
					title: "Product Type",
					key: "productType",
					scopedSlots: { customRender: "productType" },
					sorter: true,
				},
				{
					title: "Owner",
					key: "owner.email",
					scopedSlots: { customRender: "owner" },
					sorter: true,
				},
				{
					title: "Plan Type",
					key: "package.name",
					scopedSlots: { customRender: "planType" },
					sorter: true,
				},
				{
					title: "Members",
					key: "members",
					scopedSlots: { customRender: "members" },
				},
				{
					title: "Date Created",
					key: "createdAt",
					scopedSlots: { customRender: "createdAt" },
					sorter: true,
				},
				{
					title: "",
					key: "actions",
					scopedSlots: { customRender: "actions" },
				},
			];
		},
		projects() {
			return this.instances;
		},
	},
	watch: {
		search() {
			this.searchDebounce();
		},
	},
	created() {
		this.getTotalInstanceCount();
		this.getInstances();
	},

	methods: {
		...mapMutations(["SET_PROJECT", "UPDATE_SEARCH"]),

		searchDebounce: _.debounce(function () {
			this.currentPage = 1
			this.getInstances();
			this.getTotalInstanceCount();
		}, 1000),

		async getTotalInstanceCount() {
			try {
				let searchQuery = "?deleted=true";
				if (this.search) {
					searchQuery += `&_q=${this.search}`;
				}
				let { data } = await this.$api.get(
					`super-admin/instances/count${searchQuery}`
				);
				this.totalInstances = data;
			} catch (err) {
				if (err?.response?.status !== 400) {
					this.$message.error(this.$err(err, "Error while fetching project count. Please try again!"))
				}
			}
		},
		async getInstances() {
			this.loading = true;
			try {
				let start = (this.currentPage - 1) * this.pageSize;
				let searchQuery = "";
				if (this.search) {
					searchQuery = `&_q=${this.search}`;
				}
				let { data } = await this.$api.get(
					`/super-admin/instances?deleted=true&_start=${start}&_limit=${this.pageSize}&_sort=${this.sortedInfo.key}:${this.sortedInfo.order}${searchQuery}`
				);
				this.instances = data.map((x, i) => {
					const instance = {
						...x,
						allChild: x.children,
						key: i,
					};
					delete instance.children;
					return instance;
				});
			} catch (err) {
				if (err?.response?.status !== 400) {
					this.$message.error(this.$err(err, "Error while fetching project list. Please try again!"))
				}
			}
			this.loading = false;
		},

		restoreProject(project) {
			let self = this
			this.$confirm({
				title: "Restore Project",
				content: h => <div>Do you want to restore the selected project <strong>{project.name}</strong>?</div>,
				okText: 'Restore',
				cancelText: 'Cancel',
				centered: true,
				onOk() {
					self.loading = true;
					self.$api.post(`/instances/${project.id}/admin-restore-deleted`).then(() => {
						self.getInstances();
						self.$message.success('Selected project restored successfully')
					}).catch((err) => {
						if (err?.response?.status !== 400) {
							self.$message.error(self.$err(err, "Error while restoring project"));
						}
					}).finally(() => {
						self.loading = false;
					})
				},
			})
		},

		getDate(date) {
			return moment(date).format("D/M/YYYY");
		},

		handleChange(pagination, filter, sorter) {
			if (pagination) {
				this.currentPage = pagination.current;
			}
			if (sorter && sorter.column) {
				this.sortedInfo = {
					key: sorter.columnKey,
					order: sorter.order === "descend" ? "DESC" : "ASC",
				};
			}
			this.getInstances();
		},

		first3Users(instance) {
			let members = instance.users.filter((u) => u.confirmed);
			const extraMembers = members.length > 3 ? members.length - 3 : 0;
			members = members.filter((u, i) => i < 3);
			if (extraMembers) {
				members.push({ firstName: `+${extraMembers}`, id: "id" });
			}
			return members;
		},
	},

	beforeDestroy() {
		this.UPDATE_SEARCH("");
	},
};
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>

<style lang="scss" scoped>
.popoverContent {
	height: max-content;
	line-height: 30px;
	padding: 0 10px;
}

.popoverContent:hover {
	background-color: var(--off-white-light);
	color: var(--primary);
	cursor: pointer;
}
</style>

<style lang="scss">
li.ant-select-dropdown-menu-item {
	height: max-content;
	line-height: 30px;
	background-color: white !important;
}

li.ant-select-dropdown-menu-item-active {
	background-color: var(--off-white-light) !important;
}

li.ant-select-dropdown-menu-item:hover {
	color: var(--primary);
	cursor: pointer;
}

body .ant-select-dropdown-menu-item {
	padding: 5px 10px;
}

.popoverStyle .ant-popover-inner-content {
	padding: 5px;
	background-color: white;
	border-radius: 20px;
}
</style>
