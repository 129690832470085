var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"f1 apps-page w-full dF fC f1 px-4"},[_vm._m(0),_c('a-card',{staticClass:"my-3",attrs:{"bodyStyle":{ padding: 0 }}},[_c('div',[_c('a-table',{attrs:{"rowKey":(e) => e.key,"columns":_vm.columns,"dataSource":_vm.projects,"loading":_vm.loading,"pagination":{
					current: _vm.currentPage,
					total: _vm.totalInstances,
					pageSize: _vm.pageSize,
				}},on:{"change":_vm.handleChange},scopedSlots:_vm._u([{key:"readableId",fn:function(instance){return _c('div',{},[_vm._v(" "+_vm._s(instance.readableId)+" ")])}},{key:"name",fn:function(instance){return _c('div',{},[_vm._v(" "+_vm._s(instance.name)+" ")])}},{key:"projectType",fn:function(instance){return _c('div',{},[_vm._v(" "+_vm._s(instance.projectType)+" ")])}},{key:"productType",fn:function(instance){return _c('div',{},[_vm._v(" "+_vm._s(instance.productType === "lowrise" ? "Low Rise" : "High Rise")+" ")])}},{key:"owner",fn:function(instance){return _c('div',{},[(instance.owner)?_c('span',[_vm._v(_vm._s(instance.owner.email))]):_vm._e()])}},{key:"planType",fn:function(instance){return _c('div',{},[_vm._v(" "+_vm._s(instance.package && instance.package.name)+" ")])}},{key:"members",fn:function(instance){return _c('div',{staticClass:"dF aC",staticStyle:{"justify-content":"center"}},_vm._l((_vm.first3Users(instance)),function(user){return _c('a-avatar',{key:user.id,class:_vm.$style.avatar,staticStyle:{"margin-left":"-10px"},style:({
								'background-color':
									user.id === 'id'
										? '#9693e7'
										: 'var(--primary)',
							}),attrs:{"shape":"circle","size":"default"}},[_vm._v(_vm._s(user.firstName ? user.id !== "id" ? user.firstName[0].toUpperCase() : user.firstName : "--"))])}),1)}},{key:"createdAt",fn:function(instance){return _c('div',{staticStyle:{"color":"var(--med-gray)"}},[_vm._v(" "+_vm._s(_vm.getDate(instance.createdAt))+" ")])}},{key:"actions",fn:function(instance){return _c('div',{staticClass:"dF",staticStyle:{"justify-content":"flex-end"}},[_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.restoreProject(instance)}}},[_vm._v("Restore")])],1)}}])})],1)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dF aC"},[_c('div',{staticClass:"dF aC f1"},[_c('h3',[_vm._v("Archived Projects")])])])
}]

export { render, staticRenderFns }